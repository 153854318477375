<template>
  <div class="favorite-container">
    <page-top :title="$t('accounting.title')">
      <span style="font-size:26px;" class="fa fa-calculator"></span>
    </page-top>
    <el-card>
      <section class="form-select">
        <section>
          <el-date-picker
            style="width:250px;margin-right:20px;"
            v-model="dateTime"
            type="daterange"
            range-separator="~"
            :start-placeholder="$t('global.select.start')"
            :end-placeholder="$t('global.select.end')"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
          <el-select
            style="margin-right:20px;"
            v-model="select"
            :placeholder="$t('global.select.restaurant')"
          >
            <el-option label="收入" value="value1"></el-option>
            <el-option label="支出" value="value2"></el-option>
          </el-select>
          <el-button size="mini" class="ml10 table-btn btn-yellow">{{$t('global.select.btn1')}}</el-button>
        </section>
        <section>
          <el-button
            @click="dialogFormVisible = true"
            class="table-btn btn-gray"
            size="mini"
          >{{$t('global.select.btn3')}}</el-button>
        </section>
      </section>

      <el-table
        :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        style="width: 100%;margin-top:20px;"
      >
        <el-table-column type="index" width="60" align="center"></el-table-column>
        <el-table-column prop="timeRange" :label="$t('accounting.table.col1')" align="center"></el-table-column>
        <el-table-column prop="type" :label="$t('accounting.table.col2') " align="center"></el-table-column>
        <el-table-column prop="direction" :label="$t('accounting.table.col3')" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.direction == '收入'" style="color:#67C23A">{{scope.row.direction}}</span>
            <span v-else style="color:#F56C6C">{{scope.row.direction}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" :label="$t('accounting.table.col4')" align="center"></el-table-column>
        <el-table-column
          prop="opration"
          :label="$t('accounting.table.col5')"
          width="230"
          align="center"
        >
          <template slot-scope="scope">
            <el-button @click="fixed(scope.row)" class="btn-green" size="mini">修改</el-button>
            <el-button class="btn-gray" size="mini">查看</el-button>
            <el-button @click="deleteDialog = true" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <section class="pagination-container">
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.length"
        ></el-pagination>
      </section>
      <!-- <div>
        <b-btn class="table-btn btn-green">打印</b-btn>
        <b-btn class="table-btn btn-gray">下载</b-btn>
      </div>-->
    </el-card>
    <el-dialog
      :title="$t('accounting.dialog.title')"
      center
      width="50%"
      :visible.sync="dialogFormVisible"
    >
      <el-row>
        <el-col :span="16" :offset="4">
          <el-form ref="form" :model="dialogForm" label-width="80px">
            <el-form-item :label="$t('accounting.dialog.form1')">
              <el-date-picker style="width:100%" v-model="dialogForm.timeRange"></el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('accounting.dialog.form2')" label-width="80px">
              <el-input style="width:100%;" v-model="dialogForm.type"></el-input>
            </el-form-item>
            <el-form-item :label="$t('accounting.dialog.form3')" label-width="80px">
              <el-select style="width:100%;" v-model="dialogForm.direction">
                <el-option label="支出" value="支出"></el-option>
                <el-option label="收入" value="收入"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('accounting.dialog.form4')" label-width="80px">
              <el-input style="width:100%;" v-model="dialogForm.price"></el-input>
            </el-form-item>
            <el-form-item label="备注" label-width="80px">
              <el-input type="textarea" :rows="3" v-model="dialogForm.remarks"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <section slot="footer">
        <el-button class="table-btn btn-yellow">{{$t('accounting.dialog.btn1')}}</el-button>
        <el-button class="table-btn btn-gray">{{$t('accounting.dialog.btn2')}}</el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
export default {
  data() {
    return {
      select: "",
      dateTime: "",
      search: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,

      dialogFormVisible: false,
      dialogForm: {
        timeRange: "",
        type: "",
        direction: "",
        price: "",
        remarks: ""
      }
    };
  },

  components: {
    pageTop
  },
  created() {
    this.$http.get("/accounting", res => {
      this.tableData = res.data.data;
    });
  },
  methods: {
    toAdd() {
      this.$router.push("/app/addFavorite");
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    fixed(row) {
      this.dialogFormVisible = true;
      Object.assign(this.dialogForm, row);
    }
  }
};
</script>
<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  .pagination {
    float: right;
  }
}
</style>